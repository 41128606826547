body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #f7f7f7;
}

/* Nav bar */
.ui.text.top.fixed.main-menu.menu {
  background: white;
  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 60px;
}

.ui.image.logo {
  height: 50px;
  width: 120px;
}

.ui.container.main-menu {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.user-menu-item {
  width: 98px;
}

input {
  font-size: 16px!Important;
}

/* Ribbon */
.ui.centered.one.column.grid {
  padding-top: 75px;
}

/* Pet item  */
.column.pet {
  max-width: 630px;
}

.ui.segment.pet {
  margin-top: 0px;
  margin-bottom: 1em;
  padding: 0px;

}

/* Pet menu */
.ui.text.pet.menu {
  margin: 0;
  padding: 0.5em;
}

/* Pet image */
.image.pet {
  margin-bottom: 1em;
}

/* Pet slider  */
.dot-pet-external-container {
  position: relative;
  height: 2em;
}

.dot-pet-internal-container {
  margin-top: 0.6em;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dot-pet {
  border-radius: 50%;
  padding: 3px;
  margin: 2px;
  border-width: 0px;
  background-color: lightgray;
}

button[class*="--selected"] {
  background-color: lightskyblue;
}

/* Pet bottom */
.ui.container.pet-about {
  padding: 0 1em 1em 1em;
  margin: 0px!important;
}

.ui.container.pet-tags {
  padding: 0 1em 1em 1em;
  margin: 0px!important;
}

div.item.pet-tags {
  margin-left: 0px!important;
  margin-right: 1em!important;
}

.ui.divider {
  margin: 0px 1em 0px 1em;
}

.ui.container.pet-buttons {
  padding: 1em;
  margin: 0px!important;
}

a.ui.circular.icon.button {
  margin-right: 1em;
}

/* About */
div.item.about {
  margin: 0.7em;
}

div.header.about {
  padding-bottom: 0.3em;
}

/* ... */